<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Desembolso - Nuevo</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">
              <b-row>
              

                <b-col md="6">
                  <b-form-group>
                    <label>Socio: <span @click="modalClients" class="text-info link">Nuevo</span></label>
                    <v-select placeholder="Seleccione un socio" class="w-100" :filterable="false" label="full_name" v-model="partner" @search="SearchClients" :options="partners"></v-select>
                    <small v-if="errors.id_partner" class="form-text text-danger" >Seleccione un socio</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Medio de Pago:">
                    <b-form-select  v-model="check.payment_method" :options="payment_method"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Tipo de Giro:">
                    <b-form-select  v-model="check.twist_type" :options="twist_type"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Nro Referencia:">
                    <b-form-input type="text" v-model="check.number"></b-form-input>
                    <small v-if="errors.number"  class="form-text text-danger" >Ingrese un numero</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Fecha :">
                    <b-form-input type="date" class="text-center" v-model="check.date"></b-form-input>
                    <small v-if="errors.date"  class="form-text text-danger" >Ingrese una fecha</small>
                  </b-form-group>
                </b-col>


                <b-col md="2">
                  <b-form-group label="Monto:">
                    <b-form-input type="number" class="text-right" step="any" v-model="check.total"></b-form-input>
                    <small v-if="errors.total"  class="form-text text-danger" >Ingrese un monto</small>
                  </b-form-group>
                </b-col>

                <b-col md="3">
                  <b-form-group label="Doc. Adjuntos:">
                     <b-form-file
                      accept="application/pdf"
                      v-model="file"
                      :state="Boolean(file)"
                      @change="onFileChange"
                      placeholder="Selecciona un archivo"
                      drop-placeholder="Arrastre el archivo aqui"
                    ></b-form-file>
                    <small v-if="errors.file"  class="form-text text-danger" >Seleccione una archivo</small>
                  </b-form-group>
                </b-col>

                <b-col md="5">
                  <b-form-group label="Observación:">
                    <b-form-input type="text" v-model="check.observation"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="5"></b-col>
                <b-col md="2">
                  <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                </b-col>
                
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


    <ModalClients />
    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
// components
import ModalClients from './../components/ModalClient'
import LoadingComponent from './../pages/Loading'
export default {
  name: "UsuarioAdd",
  components:{
      vSelect,
      ModalClients,
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'Check',
      role: 2,
      check: {
          id_check:'',
          id_partner:'',
          twist_type:'1',
          number:'',
          date:moment(new Date()).local().format("YYYY-MM-DD"),
          description:'',
          payment_method:'008',
          file:'',
          total:'0.00',
          state:1,
      },
      file:null,
      partners: [],
      partner:null,
      payment_method: [
        {value :"008", text :'EFECTIVO'},
        {value :"007", text :'CHEQUE'},
        {value :"001", text :'DEPÓSITO EN CUENTA'},
        {value :"003", text :'TRANSFERENCIA DE FONDOS'},
      ],
      twist_type:[
        {value:'1',text:'Sepelio'},
        {value:'2',text:'Liquidación de Aportes'},
        {value:'3',text:'Gasto Administrativo'},
        {value:'4',text:'Prestamo Ordinario'},
      ],
      state:[
        {value:'1',text:'Activo'},
        {value:'0',text:'Inactivo'},
      ],

      errors: {
        id_partner: false,
        twist_type: false,
        number: false,
        date: false,
        total: false,
      },
      validate: false,
    };
  },
  mounted() {

  },
  methods: {
    AddCheck,
    Validate,
    SearchClients,
    modalClients,
    onFileChange,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};

function onFileChange(e) {
  this.check.file = e.target.files[0];
}

function modalClients() {
  EventBus.$emit('ModalClientsShow');
}

function SearchClients(search, loading) {
  
   let me = this;
    let url = this.url_base + "search-partner/" + search;
    if (search !== "") {
      loading(true);
      axios({
        method: "GET",
        url: url,
      }).then(function (response) {
            me.partners = response.data;
            loading(false);
      })
    }
}


function AddCheck() {
  let me = this;
  let url = me.url_base + "check/add";
  let data = new FormData();
  data.append("id_partner", me.partner.id);
  data.append("id_user", me.user.id_user);
  data.append("payment_method", me.check.payment_method);
  data.append("twist_type", me.check.twist_type);
  data.append("number", me.check.number);
  data.append("date", me.check.date);
  data.append("description", me.check.description);
  data.append("file", me.check.file);
  data.append("total", me.check.total);
  data.append("state", me.check.state);
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 201) {

          me.check.id_check = '';
          me.check.id_partner = '';
          me.check.twist_type = '1';
          me.check.number = '';
          me.check.date = moment(new Date()).local().format("YYYY-MM-DD");
          me.check.description = '';
          me.check.file = '';
          me.check.total = '0.00';
          me.check.state = 1;
          me.partner = null;
          me.file = null;
        
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function Validate() {
  this.errors.id_partner = this.partner == null ? true : false;
  this.errors.twist_type = this.check.twist_type.length == 0 ? true : false;
  this.errors.number = this.check.number.length == 0 ? true : false;
  this.errors.date = this.check.date.length == 0 ? true : false;
  this.errors.total = this.check.total.length == 0 ? true : false;

  if (this.errors.id_partner) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.twist_type) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.number) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.total) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }

  let me = this;
  Swal.fire({
    title: "Esta seguro de registrar el desembolso ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.AddCheck();
    }
  });
}
</script>
